$brandColor             : #00a2c7;
$lightBrandColor        : #99d9ea;
$fontColor              : #2e3e48;
$primary                : blue;
$inputBorderColorOnFocus: #00a2c7;
$inputBorderColorOnBlur : #ececfa;
$inputPlaceholderColor  : #767676;
$inputPlaceholderSize   : 11px;
$smallTextColor         : #AEAEAE;
$redColor               : #ff5a5f;
$greenColor             : #22B14C;
$lightFontColor         : #7f7f7f;
$buttonHoverColor       : #008dae;
$successColor           : #7ac142;
$messageBackground1     : #E9EBEE;
$messageBackground2     : rgb(78, 161, 214);
$emailBackground        : #edf1f4;
$lightBackgroundColor   : #f8f8f6;
$opacityBackground      : #000000;
$starColor              : #ff5a5f;
$navbarBackGround       : #ffffff;
$gradientBackgroundNavbar     : linear-gradient(to right, #dce35b, #45b649,#ffff);
$gradientBackground     : linear-gradient(to left, #dce35b,#ffff, #45b649);
$forTextColor : #45b649;
